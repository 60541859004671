
import CodeBlock from './../components/CodeBlock';
import CodeLine from './../components/CodeLine';
import TagContents from '../components/TagContents';
import { TagOpen, TagClose  } from './../components/Tags';
import style from './../styles/About.module.css';


const About = () => {
  return (
    <>
      <div className={style.circlecontainer}>
        <div className={style.circle}>
          <img className={style.memoji} src={`/images/profile.jpg`} alt="profile-pic" />
        </div>
      </div>
      <CodeBlock>
        <CodeLine>
          <TagOpen tagname={'html'} attr={'lang'} attrValue={'"en"'} />
        </CodeLine>

        <CodeLine>
          <TagOpen tagname={'head'} indent={3}/>
        </CodeLine>

        <CodeLine>
          <TagOpen tagname={'title'} indent={4}/>
        </CodeLine>

        <CodeLine>
          <TagContents indent={4} >Hello and Welcome!👋 My name is Jaewon Han, and I'm a Junior Full Stack Software Developer based in Dallas, TX.<br/>
          As a Junior Software Developer, I have experience working with both frontend and backend, and I'm constantly learning and exploring new technologies to stay up-to-date with the latest trends and best practices.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={'title'} indent={4} ></TagClose>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={'head'} indent={3}/>
        </CodeLine>
        <CodeLine>
          <TagOpen tagname={'body'} indent={3} ></TagOpen>
        </CodeLine>
        <CodeLine>
          <TagContents indent={4} > As a developer, I'm especially passionate about creating intuitive and engaging user experiences through meticulous attention to UI/UX design. I love working with others and learning from their perspectives and experiences.<br/> In my current role as a Software Developer at Calyx Software, I develop and maintain Point of Sale (POS) software using AngularJS, JavaScript, jQuery, HTML5, CSS3, and Bootstrap5. In that role, I have refactored legacy code, implemented new responsive UI designs, and presented UI recommendations to product managers to optimize user experience.
          </TagContents>
        </CodeLine>
        <CodeLine>
        <TagContents indent={4} >
          <br/>In addition to my work at Calyx Software, I'm currently pursuing a Master's degree in Computer Science at the University of Texas at Dallas, where I'm gaining expertise in cutting-edge technologies such as Machine Learning, Big Data Management, Database Management, Web Programming Languages, and Network Security.
          </TagContents>
        </CodeLine>
        <CodeLine>
        <TagContents indent={4} >
          <br/>Thank you for visiting my page, and please feel free to reach out if you have any questions or if you'd like to learn more about my work.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={'body'} indent={3} ></TagClose>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={'html'} />
        </CodeLine>
      </CodeBlock>
    </>
  )
}

export default About;