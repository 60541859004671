import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

import style from './../styles/EmailForm.module.css';

const EmailForm = () => {
  const form = useRef();
  const [sendMsg, setSendMsg] = useState('Send Message');

  const sendEmail = (e) => {
    e.preventDefault();

    setSendMsg('Sending...');

    emailjs.sendForm('service_sgj4foi', 'template_l9vpx94', form.current, 'IsmKYdth4GOXYzpgA')
      .then((result) => {
        console.log(result.text);
        setSendMsg('Message Sent!');
      }, (error) => {
        console.log(error.text);
        setSendMsg('Error Sending Message');
      });

    e.target.reset();
  };

  return (
    <>
      <form ref={form} autoComplete="off" onSubmit={sendEmail}>
        <h2 className={style.emailform_title}>Send Email 📨</h2>
        <div className={style.emailform_wrap}>
          <div className={`${style.emailform_item}`}>
            <div className={style.form_wrap}>
              <label>Name</label>
              <input type="text" name="name" required />
            </div>
            <div className={style.form_wrap}>
              <label>Email</label>
              <input type="email" name="email" required />
            </div>
            <div className={style.form_wrap}>
              <label>Subject</label>
              <input type="text" name="subject" required />
            </div>
          </div>
          <div className={`${style.emailform_item}`}>
            <div className={`${style.form_wrap} ${style.form_wrap_msg}`}>
              <label>Message</label>
              <textarea name="message" required />
            </div>
            <input type="submit" value={sendMsg} />
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailForm;
